import React, { Component } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaHome, FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "gatsby";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first: "",
      last: "",
      message: "",
      telephone: "",
      display: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }
  sendMessage = (event) => {
    event.preventDefault();
    this.setState({ display: false });
    
    const apiUrl = "https://birk.zendesk.com/api/v2/requests.json";
    const formData = {
      request: {
        requester: { 
          name: `${this.state.first} ${this.state.last}`,
          email: `${this.state.email}`
        },
        subject: "Getting in touch",
        comment: {
          body: `${this.state.message}\r\nTelephone: ${this.state.telephone}\r\nEmail: ${this.state.email}`,
        },
      },
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    fetch(apiUrl, options, `Jrf7BjvmmAs4sSEGmyiHk9c5SCvtwuk3e6c5Sh1u`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            email: this.state.email,
            first: this.state.first,
            last: this.state.last,
            help: this.state.help,
            telephone: this.state.telephone,
          });
          toast.success(
            "We have received your message and  We will get back to you soon",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        },
        (error) => {
          this.setState({ error });
          toast.error("Something went wrong. Please try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      );
  };

  handleClose = () => this.setState({ display: false });
  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <footer className="footer-section">
          <div className="foo_box">
            <div className="container-fluid all-section">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8">
                  <div className="gBox">
                    <h5>
                      Get in touch for a free design consultation at your home
                    </h5>
                    <button
                      onClick={() => this.setState({ display: true })}
                      className="btn btn-darkB"
                      data-toggle="modal"
                      data-target="#get_quote"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-middle">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="foot_logo">
                    <div className="brik-white mb-2">
                      <StaticImage
                        src="../images/birk-logo-white.png"
                        alt="logo"
                        className="img-fluid mw_logo"
                      />
                    </div>
                    <div className="place">
                      <div className="footer-pad">
                        <div className="foot_heading">
                          <h4>Contact Us</h4>
                        </div>
                        <ul className="list-unstyled">
                          <li className="d-flex align-item-center">
                            <span className="foo_cir mr-2">
                              <FaHome />
                            </span>
                            <a href="/">
                              2/55 Northgate Drive Thomastown, VIC, 3074
                              Australia
                            </a>
                          </li>
                          <li>
                            <span className="foo_cir mr-2">
                              <FaPhone />
                            </span>
                            <a href="/">1300 664 991</a>
                          </li>
                          <li>
                            <span className="foo_cir mr-2">
                              <FaEnvelope />
                            </span>
                            <a href="/">sales@birk.com.au</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 copy">
                  <p className="text-left text-light">
                    &copy; Birk {new Date().getFullYear()}. All rights reserved.
                  </p>
                  <ul className="list-unstyled text-right">
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/term-sale">Terms of sale</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Modal
          show={this.state.display}
          responsive={true}
          onHide={() => this.setState({ display: false })}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Get a Quote
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="about-form">
                    <form onSubmit={this.sendMessage}>
                      <div className="form-group">
                        <label
                          htmlFor="about-First"
                          className="font-weight-bold"
                        >
                          First Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-First"
                          name="first"
                          value={this.state.first}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-Last"
                          className="font-weight-bold"
                        >
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-Last"
                          name="last"
                          value={this.state.last}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="telephone" className="font-weight-bold">
                          Telephone number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="telephone"
                          name="telephone"
                          value={this.state.telephone}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-Email"
                          className="font-weight-bold"
                        >
                          Email address<span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="about-Email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-Help"
                          className="font-weight-bold"
                        >
                          Comments<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="about-Help"
                          rows="3"
                          name="message"
                          value={this.state.message}
                          onChange={this.handleInputChange.bind(this)}
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-dark text-uppercase"
                      >
                        submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button className="btn btn-dark">Save Changes</Button>
          </Modal.Footer> */}
        </Modal>
      </React.Fragment>
    );
  }
}
export default Footer;
