import React, { useEffect, useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { FaFacebookF, FaInstagram, FaPinterestP } from "react-icons/fa";
import ReactGA from "react-ga";

const Header = (props) => {
  const [navbar, setNavbar] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(undefined);
  const headerRef = useRef(null);

  const [active, setActive] = useState("");
  const [upper, setUpper] = useState("");

  useEffect(() => {
    // Init GA tracking
    ReactGA.initialize("UA-7268043-5");
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    const url = window.location.pathname;
    const head = window.location.hash;
    setUpper(head);
    // console.log(upper, "main");
    setActive(url);
    // console.log(active);
    let isMounted = true;
    if (isMounted) {
      window.addEventListener("scroll", handlePoint);
    }
    return () => {
      isMounted = false;
    };
  }, [upper, active]);

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, [])

  const handlePoint = () => {
    // console.log(window.scrollY);
    if (window.scrollY > 1) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const CloseNav = () => {
    document.getElementById("myNav").style.height = "0%";
  };
  const OpenNav = () => {
    document.getElementById("myNav").style.cssText =
      "height: 100%; overflow-y: scroll;";
  };

  return (
    <React.Fragment>
      <header className="header-section" ref={headerRef} style={{ height: navbar ? headerHeight : 'inherit'}}>
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg navbar-light bg-white cusArrow sticky"
              : "navbar navbar-expand-lg navbar-light bg-white cusArrow"
          }
        >
          <a className="navbar-brand" href="/">
            <StaticImage
              src="../images/Top.png"
              alt="logo"
              placeholder=""
              className="img-fluid mw_logo"
              placeholder="blurred"
              loading="eager"
            />
          </a>
          <span
            className="mobi_btn"
            onClick={OpenNav}
            role="button"
            tabIndex="0"
            onKeyDown={OpenNav}
          >
            &#9776;
          </span>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <Link
                  className={active === "/" ? "nav-link active" : "nav-link"}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={
                    active === "/about"
                      ? "nav-link active"
                      : upper === "#about-us"
                      ? "nav-link active"
                      : upper === "#mission"
                      ? "nav-link active"
                      : upper === "#vision"
                      ? "nav-link active"
                      : upper === "#sustainability"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/about"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About us
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    className="dropdown-item font_style"
                    to="/about/#about-us"
                  >
                    About us
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/about/#mission"
                  >
                    Mission
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/about/#vision"
                  >
                    Vision
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/about/#sustainability"
                  >
                    Sustainability
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={
                    active === "/gallery" ? "nav-link active" : "nav-link"
                  }
                  to="/gallery"
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={
                    active === "/product"
                      ? "nav-link active"
                      : active === "/roller-blinds"
                      ? "nav-link active"
                      : active === "/roman-blinds"
                      ? "nav-link active"
                      : active === "/curtains"
                      ? "nav-link active"
                      : active === "/shutters"
                      ? "nav-link active"
                      : active === "/venetians"
                      ? "nav-link active"
                      : active === "/external-range"
                      ? "nav-link active"
                      : active === "/automation"
                      ? "nav-link active"
                      : active === "/honeycomb"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/product"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Product
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    className="dropdown-item font_style"
                    to="/roller-blinds"
                  >
                    Roller Blinds
                  </Link>
                  <Link className="dropdown-item font_style" to="/roman-blinds">
                    Roman Blinds
                  </Link>
                  <Link className="dropdown-item font_style" to="/curtains">
                    Curtains
                  </Link>
                  <Link className="dropdown-item font_style" to="/shutters">
                    Shutters
                  </Link>
                  <Link className="dropdown-item font_style" to="/venetians">
                    Venetians
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/external-range"
                  >
                    External Range
                  </Link>
                  <Link className="dropdown-item font_style" to="/automation">
                    Automation
                  </Link>
                  <Link className="dropdown-item font_style" to="/honeycomb">
                    Honeycomb
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={
                    active === "/service"
                      ? "nav-link active"
                      : active === "/developer"
                      ? "nav-link active"
                      : active === "/builders"
                      ? "nav-link active"
                      : active === "/architect-designer"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="/service"
                >
                  Partner with us
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item font_style" to="/developer">
                    Developers
                  </Link>
                  <Link className="dropdown-item font_style" to="/builders">
                    Builders
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/architect-designer"
                  >
                    Architects & Designers
                  </Link>
                </div>
              </li>
              {/* <li className="nav-item dropdown">
                <Link
                  className={
                    active === "/promotions"
                      ? "nav-link active"
                      : active === "/roller-blinds-promotion"
                      ? "nav-link active"
                      : active === "/shutters-promotion"
                      ? "nav-link active"
                      : active === "/external-blinds-promotion"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="/promotions"
                >
                  Promotions
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item font_style" to="/roller-blinds-promotion">
                  Roman Blinds
                  </Link>
                  <Link className="dropdown-item font_style" to="/shutters-promotion">
                  Shutters
                  </Link>
                  <Link
                    className="dropdown-item font_style"
                    to="/external-blinds-promotion"
                  >
                    External Blinds
                  </Link>
                </div>
              </li> */}
              <li className="nav-item">
                <Link
                  className={
                    active === "/contact-us" ? "nav-link active" : "nav-link"
                  }
                  to="/contact-us"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>

          <div id="myNav" className="b_overlay">
            <span
              className="closebtn"
              onClick={CloseNav}
              onKeyDown={CloseNav}
              role="button"
              tabIndex="0"
            >
              &times;
            </span>
            <div className="b_overlay-content">
              <nav className="navigation">
                <ul className="mainmenu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link className="text-light" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="text-light" to="/product">
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link className="text-light" to="/service">
                      Partner with us
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </nav>
      </header>

      <div className="social-section">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/birkblinds"
              target="_blank"
              title="Facebook"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/birkblinds/"
              target="_blank"
              title="Instagram"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.pinterest.com/birkblinds/"
              target="_blank"
              title="Pinterest"
              rel="noreferrer"
            >
              <FaPinterestP />
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Header;
